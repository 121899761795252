
import { defineComponent } from 'vue';
import Info from '@/components/sections/Info.vue';
import { mapActions } from 'vuex';
import { Actions, Getters } from '@/store/enums/VerifyAccountEnums';

export default defineComponent({
  name: 'check-tenant-review',
  components: {
    Info,
  },
  methods: {
    ...mapActions({
      sendVerificationEmail: Actions.REQUEST_NEW_TOKEN,
    }),
    handleClickRequest() {
      this.sendVerificationEmail();
    },
  },
});
